:root[data-theme="light"] {
  --colors-gradientVioletAlt: #fff;
  /* --colors-gradientVioletAlt: #000; */
  --colors-cardBorder: #6f6565;
  --colors-textSubtle: #fff;
  --colors-secondary: ;
  --colors-primaryBright: #ffb800;
  --colors-success: #1aae69;
  --colors-primary: #4b84f3;
  --colors-textDisabled: #000;
  --colors-textSubtle: #000;
  --colors-white: #000;
  --colors-gradientGold: #4b84f3;

  .matchbox {
    background-color: rgb(216 212 212 / 23%);
    padding-inline: 15px;
    border-radius: 6px;
    padding-block: 15px;
    border: 1px solid #4b84f3;
  }
  .drawticket {
      background: linear-gradient(89.96deg, hsla(0, 0%, 100%, 0.05) 0.03%, hsla(0, 0%, 100%, 0.008) 49.67%, hsla(0, 0%, 100%, 0.05) 99.96%) !important ;
      -webkit-backdrop-filter: blur(4px) !important;
      backdrop-filter: blur(4px);
      padding: 5px;
      border-radius: 10px;
      display: inline-flex;
      border: 1px solid hsla(0, 0%, 100%, 0.164);
    }
  .drawcounttext {
    color: #000;
  }
  .iowsGo {
    color: #000;
  }

  .toggled {
    background-color: #4b84f3 !important;
  }

  .my-custom-ribbon {
    background: #4b84f3;
  }

  .my-custom-ribbon::before {
    background: #4b84f3;
  }

  .my-custom-ribbon::after {
    background: #4b84f3;
  }

  .anotherClass {
    background-color: #2d67d8;
  }

  .ribbontittle {
    background: #4b84f3;
  }

  .numberticket {
    color: #000;
  }

  .balls {
    color: #000 !important;
  }

  .tickettext {
    color: #fff;
  }
}
.exbtn{
  background: rgb(54,112,193);
background: linear-gradient(162deg, rgba(54,112,193,1) 58%, rgba(224,212,65,0.8183648459383753) 95%);
border-radius: 5px;
}

:root[data-theme="dark"] {
  --colors-gradientVioletAlt: #fff;
  --colors-gradientVioletAlt: #000;
  --colors-cardBorder: #6f6565;
  --colors-textSubtle: #fff;
  --colors-secondary: ;
  --colors-primaryBright: #ffb800;
  --colors-success: #1aae69;
  --colors-primary: #498fec;
  --colors-textDisabled: #fff;
  --colors-primary: #35b6e6;
  --colors-failure: rgb(233 69 69);
  --colors-gradientGold: linear-gradient(162deg, rgba(54, 112, 193, 1) 58%, rgba(224, 212, 65, 0.8183648459383753) 95%);
  --colors-backgroundAlt: #000;

  
}
.matchbox {
  background-color: rgba(22, 27, 22, 0.8509803922);
  padding-inline: 15px;
  border-radius: 6px;
  padding-block: 15px;
  border: 1px solid rgba(54, 112, 193, 1) ;
  background: linear-gradient(162deg, rgb(54 112 193 / 27%) 58%, rgb(224 212 65 / 20%) 95%);
}
.CardHeader__StyledCardHeader-sc-b1def301-0 {
  border-radius: 22px 22px 0 0;
}



.ribbontittle {
  background: #4b84f3 !important;
}


.imgticket {
  position: relative;
}
.tickettext {
  position: absolute;
  top: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
  text-align: center;
  right: 26%;
  line-height: 0.7;
}
.rightmascot {
  position: absolute;
  bottom: -22px;
  right: 166px;
}
.tickettext p {
  font-size: 45px;
  text-align: center;
  font-weight: 800;
  background: linear-gradient(162deg, rgba(54, 112, 193, 1) 58%, rgba(224, 212, 65, 0.82) 95%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tickettext h2 {
  font-size: 37px;
  text-align: center;
}

.hfgtext {
  font-size: 40px;
  font-weight: 700;
  padding: 10px;
  text-align: center;
  color: #e3bb09;
  position: absolute;
  top: 37%;
  right: 37%;
  /* margin-top: 8px; */
  /* margin: 8px auto; */
}
.tickettittleprize{
  font-size: 46px;
  font-weight: bolder;
  padding: 10px;
  text-align: center;
  position: absolute;
  top: 29%;
  right: 25%;
  color: #dbe0f4;
  margin-top: 20px;
}
.btntickets{
  font-size: 36px;
  font-weight: bolder;
  padding: 10px;
  text-align: center;
  position: absolute;
  bottom: 29%;
  right: 26%;
  text-transform: capitalize;
  color: #119ce5;
}

.ticketbtn {
  margin-top: 35px;
  z-index: 99;
}

.numberticket {
  color: #fff;
}
.starimg{
    position: absolute;
    top: 33%;
    left: 50%;
    transform: translate(-50%,-50%);
    animation: blink 2s linear infinite;
    z-index: -9;
    width: 570px;
}
@media screen and (max-width: 624px) {
  .starimg{
    top: 20%;
  }
}
@media screen and (max-width: 375px) {
  .starimg{
    top: 25%;
  }
}

.rayimg{
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%,-50%);
    animation: rayRounded 20s linear infinite;
    z-index: -9;
}
@keyframes rayRounded {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
}
100% {
    transform: translate(-50%, -50%) rotate(1turn);
}
  
}
@keyframes blink {
  0% {
    opacity: 1;
}
25% {
    opacity: .6;
}
50% {
    opacity: 1;
}
75% {
    opacity: .6;
}
100% {
    opacity: 1;
}
  
}
.imgticket .desktopticket {
  display: block;
   cursor: pointer;
}
.imgticket .mediaticket {
  display: none;
} 
.desktoptickettext{
  display: block;
}
.mobiletickettext{
  display: none;
}
/* @media screen and (max-width: 1024px) {

  .hfgtext {
    top: 20%;
    right: 18%;
    font-size: 40px;
  
  }
  .tickettittleprize{
    top: 29%;
    right: 23%;
    font-size: 40px;

  }
  .btntickets{
    bottom: 27%;
    right: 24%;
    font-size: 30px;

  }
  
} */
/* @media screen and (max-width: 900px) {
  .tickettittleprize {
    font-size: 30px;
    top: 26%;
  }
  .hfgtext {
    top: 17%;
    right: 18%;
    font-size: 33px;
    margin-top: 10px; 
  }

  .btntickets{
    bottom: 25%;
    right: 24%;
    font-size: 25px;
  }
} */
/* @media screen and (max-width: 768px) {
  
  .btntickets {
    bottom: 27%;
    right: 23%;
}
.tickettittleprize {
  top: 27%;
  right: 23%;
  font-size: 30px;
}
.hfgtext {
  top: 17%;
  right: 17%;
  font-size: 30px;
}
.btntickets{
  bottom: 25%;
  right: 24%;
  font-size: 25px;
}
} */
/* @media screen and (max-width: 720px) {
  .btntickets {
    bottom: 26%;
    right: 24%;
    font-size: 21px;
}
.tickettittleprize {
  font-size: 20px;
  top: 26%;
}
.hfgtext {
  top: 17%;
  right: 16%;
  font-size: 25px;
}
} */
/* @media screen and (max-width: 680px) {
  .btntickets{
    font-size: 22px;
  }
  .tickettittleprize{
    font-size: 20px;
  }
  .hfgtext{
    font-size: 25px;
  }
} */
/* @media screen and (max-width: 650px) {
  .btntickets{
    font-size: 20px;
  }
  
} */
/* @media screen and (max-width: 575px) {
  

  .imgticket .mediaticket{
    display: block;
    filter: drop-shadow(1px 6px 3px #f4c36be0);

  }
  .desktoptickettext{
    display: none;
  }
  .mobiletickettext{
    display: block;
  }
  .mobilehfgtext {
    font-size: 39px;
    font-weight: bolder;
    padding: 10px;
    text-align: center;
    color: #e3bb09;
    position: absolute;
    top: 51%;
    right: 17%;
}
.mobiletickettittleprize {
  font-size: 40px;
  font-weight: bolder;
  padding: 10px;
  text-align: center;
  position: absolute;
  top: 54%;
  right: 26%;
  color: #dbe0f4;
  margin-top: 20px;
}
.mobilebtntickets {
  font-size: 33px;
  font-weight: bolder;
  padding: 10px;
  text-align: center;
  position: absolute;
  bottom: 19%;
  right: 26%;
  text-transform: capitalize;
  color: #119ce5;
}
  
} */
/* @media screen and (max-width: 500px) {
 
  .mobilehfgtext {
    font-size: 31px;
    right: 20%;
  }
  .mobiletickettittleprize{
    font-size: 37px;
  }
  .mobilebtntickets{
    font-size: 26px;
  }
  

} */
/* @media screen and (max-width: 425px) {
  .mobilehfgtext {
    font-size: 25px;
  
  }
  .mobiletickettittleprize{
    font-size: 30px;
  }
  .mobilebtntickets{
    font-size: 25px;
  }
} */
/* @media screen and (max-width: 390px) {

  .mobilehfgtext {
    font-size: 25px;
  
  }
  .mobiletickettittleprize{
    font-size: 30px;
  }
  .mobilebtntickets{
    font-size: 25px;
  }
} */
/* @media screen and (max-width: 360px) {

  .mobilehfgtext {
    font-size: 20px;
  
  }
  .mobiletickettittleprize{
    font-size: 22px;
    right: 27%;
  }
  .mobilebtntickets{
    font-size: 20px;
  }
} */
/* @media screen and (max-width: 320px) {

  .mobilehfgtext {
    font-size: 20px;
  
  }
  .mobiletickettittleprize{
    font-size: 22px;
  }
  .mobilebtntickets{
    font-size: 20px;
  }
} */
@media screen and (max-width: 992px) {
}
@media screen and (max-width: 768px) {
}
